import { ChunkLoadedAnalyticsEventInterface } from "./events/ChunkLoadedAnalyticsEventInterface";
import { DataLoadedEventInterface } from "./events/DataLoadedAnalyticsEventInterface";
import { ImageLoadedAnalyticsEventInterface } from "./events/ImageLoadedAnalyticsEventInterface";
import { LoadImageErrorAnalyticsEventInterface } from "./events/LoadImageErrorAnalyticsEventInterface";
import { WidgetInjectedAnalyticsEventInterface } from "./events/WidgeInjectedAnalyticsEventInterface";
import { WidgetInjectedWithAttributeAnalyticsEventInterface } from "./events/WidgeInjectedWithAttributeAnalyticsEventInterface";

export class AnalyticsEventsFactory {
    public constructor(
        private performance: Performance | undefined,
        private readonly userAgent: string,
        private readonly location: string,
        private readonly app: string
    ) {}

    private getHeaders() {
        const url = new URL(this.location);

        return {
            app: this.app,
            userAgent: this.userAgent,
            location: url.origin + url.pathname,
            dateTime: new Date(),
        };
    }

    private getTimeFromDomComplete() {
        if (!this.performance) return -1;
        const navigationEvent = this.performance?.getEntriesByType(
            "navigation"
        )?.[0] as PerformanceNavigationTiming;
        if (!navigationEvent) return -1;
        if (!navigationEvent.domComplete) return -1;
        return this.performance.now() - navigationEvent.domComplete;
    }

    public createWidgetInjected(
        widgetName: string
    ): WidgetInjectedAnalyticsEventInterface {
        return {
            type: "FE.WidgetInjected",
            headers: this.getHeaders(),
            payload: {
                widgetName,
                timeFromDomComplete: this.getTimeFromDomComplete(),
            },
        };
    }

    public createWidgetInjectedWithAttribute(
        widgetName: string,
        dataAttribute: string
    ): WidgetInjectedWithAttributeAnalyticsEventInterface {
        return {
            type: "FE.WidgetInjectedWithAttribute",
            headers: this.getHeaders(),
            payload: {
                widgetName,
                dataAttribute,
            },
        };
    }

    public createChunkLoaded(
        scriptName: string,
        duration: number
    ): ChunkLoadedAnalyticsEventInterface {
        return {
            type: "FE.ChunkLoaded",
            headers: this.getHeaders(),
            payload: {
                scriptName,
                duration,
                timeFromDomComplete: this.getTimeFromDomComplete(),
            },
        };
    }

    public createDataLoaded(
        endpoint: string,
        duration: number
    ): DataLoadedEventInterface {
        return {
            type: "FE.DataLoaded",
            headers: this.getHeaders(),
            payload: {
                endpoint,
                duration,
            },
        };
    }

    public createImageLoaded(
        imageUrl: string,
        duration: number
    ): ImageLoadedAnalyticsEventInterface {
        return {
            type: "FE.ImageLoaded",
            headers: this.getHeaders(),
            payload: {
                imageUrl,
                duration,
            },
        };
    }

    public createLoadImageError(
        imageUrl: string
    ): LoadImageErrorAnalyticsEventInterface {
        return {
            type: "FE.LoadImageError",
            headers: this.getHeaders(),
            payload: {
                imageUrl,
            },
        };
    }
}
